<template>
    <b-card no-body style="height: 364px;" :style="style" class="offer-card">
        <b-card-body v-if="item.type === 1" style="padding: 16px;">
            <b-card-title style="font-size: 20px; padding: 0; margin: 0">
                <div class="d-flex" style="justify-content: space-between; flex-direction: column;">
                    <div class="d-flex">
                        <span style="margin-right: 5px">{{ $i18n.locale == 'ru' ? item.title : item.title_en || item.title }}</span> <span style="color: var(--theme-color)">{{ item.ths }} TH/s </span> 
                        <b-img
                            src="~@/assets/images/icons/emoji/fire.png"
                            fluid
                            alt="bonus"
                            style="width: 20px; height: 20px;"
                        ></b-img>
                    </div>
                    <div class="d-flex justify-content-start">
                        <b-badge variant="success" style="background-color: #3ED085; color: #FFF;">{{ $i18n.locale == 'ru' ? item.caption : item.caption_en || item.caption }}</b-badge>
                    </div>
                </div>
            </b-card-title>
            <div class="delimiter" style="width: 100%; height: 1px; margin: 12px 0;"></div>
          
            <div style="font-size: 12px;">
                <div class="d-flex justify-content-between" style="margin: 8px 0; font-size: 12px;  align-items: flex-end;">
                    <span class="text-muted font-weight-light">
                        <!-- Мощность контракта -->
                        {{$t('offer-card.contract-value')}}
                    </span>
                    <div class="d-flex card-ths" style="flex-direction: column; align-items: end;">
                        <div style="font-size: 10px; text-align: end">
                            <span v-if="item.discount_percent && item.discount_percent > 0" style="color: rgb(62, 208, 133)">
                                +{{item.discount_percent.formatMoney(1," "," ")}}%
                            </span>
                            <span v-if="item.old_ths && item.old_ths > 0" class="line-through"> 
                                {{item.old_ths.formatMoney(2," ", " ")}} TH/s
                            </span>
                        </div>
                        <span style="width: 100%; text-align: end;">
                            {{ item.ths.formatMoney(2," ", " ") }} TH/s
                        </span>
                    </div>
                </div>
                <div class="d-flex justify-content-between" style="margin: 8px 0; font-size: 12px;">
                    <span class="text-muted font-weight-light">
                        <!-- Плата за электроэнергию -->
                        {{$t('offer-card.wheeling-charge')}}
                    </span>
                    <span>
                       {{ (item.ths * 0.05).formatMoney(2," ", " ") }} $
                    </span>
                </div>
                <div class="d-flex justify-content-between" style="margin: 8px 0; font-size: 12px;">
                    <span class="text-muted font-weight-light">
                        <!-- Срок контракта -->
                        {{$t('offer-card.contract-term')}}
                    </span>
                    <span>
                       {{ item.period }} мес.
                    </span>
                </div>
                <div class="d-flex justify-content-between" style="margin: 8px 0; font-size: 12px;">
                    <span class="text-muted font-weight-light" >
                        <!-- Ежедневный доход -->
                        {{$t('offer-card.daily-income')}}
                    </span>
                    <span>
                       {{ profit.formatMoney(2," "," ") }} $
                    </span>
                </div>

                <div class="d-flex justify-content-between" style="margin: 8px 0; font-size: 12px;">
                    <span class="text-muted font-weight-light" >
                        <!-- Ожидаемый доход  -->
                        {{$t('offer-card.expected-income')}}
                        <feather-icon 
                            v-b-popover.hover.top="$t('popovers.income-with-calculator')"
                            icon="AlertCircleIcon" 
                            class="cursor-pointer info-hover text-muted"
                            style="margin: 3px" 
                        />
                    </span>
                    <span class="font-weight-light">
                       {{ summary.formatMoney(2," "," ") }} $
                    </span>
                </div>
              
               
            </div>
            <div class="delimiter" style="width: 100%; height: 1px; margin: 12px 0;"></div>

            <div class="d-flex justify-content-between" style="margin: 8px 0; font-size: 12px;">

                <i18n path="offer-card.contract-price" class="text-muted font-weight-light" tag="span">
                    <br>
                </i18n>
                <div class="d-flex card-prices" style="align-items: center;">
                    <span class="card-price" style="font-size: 18px; font-weight: 500;">
                        {{ item.price_amount.formatMoney(0," "," ") }} $
                    </span>
                    <span v-if="item.old_price_amount && item.old_price_amount > 0" class="line-through ml-1 card-old-price" style="font-size: 12px; font-weight: 500;">
                        {{ item.old_price_amount.formatMoney(0," "," ") }} $
                    </span>
                </div>
            </div>

            <b-button @click="order(item)" size="md" variant="gray" class="offer-action">
                {{ $i18n.locale == 'ru' ? item.action : item.action_en || item.action }}
            </b-button>

        </b-card-body>
        <template v-else>
            <b-card-body class="d-flex flex-column justify-content-between" style="padding: 16px 16px 8px 16px">
                <div class="d-flex" style="align-items: baseline; justify-content: space-between; margin-bottom: 10px;">
                    <b-card-text class="text-center text-black font-weight-bolder" style="font-size: 16px; margin-bottom: 0px;">
                        {{ item.miner.name }}
                    </b-card-text>

                    <div class="d-flex justify-content-end">
                        <b-badge v-if="item.discount_percent" variant="success" style="background-color: #3ED085; color: #FFF; font-size: 12px;">-{{ item.discount_percent.formatMoney(1," "," ") }}%</b-badge>
                    </div>

                </div>
                <div :style="mediaViewStyle()"></div>

                <div style="font-size: 12px;">
                    <div class="d-flex justify-content-between" style="margin: 8px 0; font-size: 12px;  align-items: flex-end;">
                        <span class="text-muted font-weight-light">
                            {{$t('offer-card.miner-power')}}
                        </span>
                        <span>
                            {{ item.miner.hashrate }} TH/s
                        </span>
                    </div>
                   
                   <div class="d-flex justify-content-between" style="margin: 8px 0; font-size: 12px;  align-items: flex-end;">
                        <span class="text-muted font-weight-light">
                            {{$t('offer-card.energy-efficiency')}}
                        </span>
                        <span>
                            {{ performance }} w/t
                        </span>
                    </div>

                    <div class="d-flex justify-content-between" style="margin: 8px 0; font-size: 12px;  align-items: flex-end;">
                        <span class="text-muted font-weight-light">
                            {{$t('offer-card.income-per-day')}}
                        </span>
                        <span>
                            {{ daily_currency.formatMoney(2," "," ") }} $
                        </span>
                    </div>

                    <div class="d-flex justify-content-between" style="margin: 8px 0; font-size: 12px;  align-items: flex-end;">
                        <span class="text-muted font-weight-light">
                            {{$t('offer-card.expected-income')}}
                            <feather-icon 
                                v-b-popover.hover.top="$t('popovers.income-miner')"
                                icon="AlertCircleIcon" 
                                class="cursor-pointer info-hover text-muted"
                                style="margin: 3px" 
                            />
                        </span>
                        <span>
                            {{ summary_miner.formatMoney(2," "," ") }} $
                        </span>
                    </div>

                </div>
                <div class="d-flex align-items-center" style="justify-content: center;">
                    <span class="text-black" style="font-size: 18px; font-weight: 500;">
                        {{ item.price_amount.formatMoney(0," "," ") }} $
                    </span>
                    <span v-if="item.old_price_amount && item.old_price_amount > 0" class="text-black line-through ml-1" style="font-size: 12px; font-weight: 500;">
                        {{ item.old_price_amount.formatMoney(0," "," ") }} $
                    </span>
                </div>

                <div class="d-flex align-items-center justify-content-between">
                    <b-button @click="order(item)" size="md" variant="gray" class="offer-action">
                        {{ $i18n.locale == 'ru' ? item.action : item.action_en || item.action }}
                    </b-button>
                </div>
            </b-card-body>
        </template>
    </b-card>
</template>

<script>

    import miners from "@/modules/contents/miners"

    export default {

        data() {
            return {
                miners,

                diff_percent: 0,
                market_price_percent: 6,

                individual_style: {
                    width: this.width ? `${this.width}px` : '100%',
                    height: this.height ? `${this.height}px` : '100%',
                    overflow: 'hidden',
                    margin: '0 auto',
                    transform: "scale(2)"
                }
            }
        },
        props: {
            item: Object,
            isPreview: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            mediaViewStyle() {
                return {
                    height: '100px',
                    width: '100%',
                    minWidth: '100%',
                    backgroundImage: `url(${this.item.miner.medias[0].urls.thumb})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundColor: "white",
                    borderRadius: "12px"
                }
            },
            order(  ) {
                
                if( this.isPreview ) return;
                
                if( this.item.type === 1 || this.item.type === 3) {
                    this.$router.push({
                        name: "user-contracts-create",
                        query: {
                            'with-offer': this.item.id
                        }
                    });
                } else if( this.item.type === 2 ) {
                    this.$set( this.miners.cart, this.item.miner.id, { miner: this.item.miner, count: 1, guarantee: { period: 'none', ths: false, amount: 0 } });
                    this.$router.push({
                        name: "user-miners-checkout",
                        query: {
                            'with-offer': this.item.id
                        }
                    });
                }
                
            },
        },
        components: {

        },
        computed: {

            style() {
                if (!this.item.hasOwnProperty('special') || !this.isPreview) return {margin: '0 12px'};

                if (this.item.hasOwnProperty('special') && this.item.special === 'web') {
                    if (this.item.type === 1) return {margin: '0px 0px 180px 0px'}
                    else return {margin: '0px 0px 50px 0px'}
                } else if (this.item.hasOwnProperty('special') && this.item.special === 'individual') {
                    if (this.item.type === 1) return {margin: '0px 0px 80px 0px'}
                    else return {margin: '0px 0px 80px 0px'}
                }
            },
            
            fee() {
                return (this.period_profit * 0.025) * this.market_price;
            },

            profit_btc() {
                 return (((84600 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.item.ths )) / ( this.$user.contracts.values.D * Math.pow(2, 32) )) );
            },
            
            profit() {
                return this.profit_btc * this.$blockchain.tickers.values.USD.last;
            },

            summary() {
                return (this.period_profit * this.market_price) - (this.energy_payout * this.item.period) - this.fee;
            },

            period_profit() {
               
                return (((84600 * 30 * this.item.period * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.item.ths )) / ( this.difficult * Math.pow(2, 32) )) );
            },

            period_profit_miner() {
                return (((84600 * 30 * 36 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.item.miner.hashrate )) / ( this.difficult * Math.pow(2, 32) )) );
            },

            market_price() {

                let temp = this.$blockchain.tickers.values.USD.last * ( this.market_price_percent / 100 );

                let growing = 0;
                if (this.item.type === 2) {
                    growing = temp * 36;
                } else {
                    growing = temp * this.item.period;
                }
                
                return this.$blockchain.tickers.values.USD.last + growing;
                
            },

            difficult() {
                
                let temp = parseInt(this.$user.contracts.values.D) * (this.diff_percent / 100);
                let growing = 0;
                if (this.item.type === 2) {
                    growing = temp * 36;
                } else {
                    growing = temp * this.item.period;
                }
                let diff = parseInt(this.$user.contracts.values.D) + growing;
                
                if( diff <= 1000000000 ) {
                    diff = 1000000000;
                }
                                
                return parseInt(diff);
                
            },

            energy_payout() {
                return ( this.item.ths * ( this.$user.contracts.values.E * 24 * 30 ) ); 
            },

            performance() {
                return Math.trunc(this.item.miner.energy_usage / this.item.miner.hashrate);
            },

            daily_profit() {
                
                let hashrate = this.item.miner.hashrate;
                
                return ( ((84600 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * hashrate )) / ( this.$user.contracts.values.D * Math.pow(2, 32) )) ).toFixed(8);
                
            },

            daily_currency() {
               return (this.daily_profit * this.$blockchain.tickers.values["USD"].last);  
            },

            summary_miner() {
                return this.period_profit_miner * this.market_price - this.fee_miner - this.service - this.energy_miner;
            },

            fee_miner() {
                return (this.period_profit_miner * 0.025) * this.market_price;
            },

            service() {
                return (this.period_profit_miner * 0.05) * this.market_price;
            },
            
            energy_miner() {
                
                let btc = this.item.miner.service_period * (((( (this.item.miner.energy_usage / 1000) * this.$user.contracts.values.K) * 24 )) / this.$blockchain.tickers.values['USD'].last) ;
                                                  
                return btc * this.market_price;
                
            },
            
        },
        watch: {

        },
        mounted() {

        }

    }

</script>

<style lang="scss">
    .offer-action {
        margin-top: 4px; 
        width: 100%; 
        padding: 18px 28px; 
        border-radius: 16px; 
        background-color: #FFF; 
        border: 2px solid #EBECF2;
    }
</style>