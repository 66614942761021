

    import $request from "@/modules/request/"

    export default {
            
         until: new Date().getTime(),
         items: [],
         get() {
            if( this.items.length > 0 ) return;
            $request.get("contracts.getOffers").then( rsp => {
                this.until = rsp.until;
                this.items = rsp.items;
            });
         },   
            
    }