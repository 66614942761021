<template>
    <b-card v-if="offers.items.length > 0" no-body class="card-special-v2" style="margin: 0 12px;">
        <showAt breakpoint="mediumAndAbove">
            <b-card-body>
            
                <div class="d-flex" style="height: 100%">
                    <div class="d-flex" style="flex-direction: column; justify-content: space-evenly; padding: 16px 0px 16px 24px; width: 57%;">
                        <countdown :end-time="new Date(offers.until * 1000).getTime()" ref="countdown">
                            
                            <template
                                v-slot:process="countdown">
                                <b-card-title class="d-flex" style="align-items: flex-start; justify-content: space-between;">

                                    <div class="d-flex" style="">  
                                        <b-badge variant="success" style="padding: 8px 16px; text-transform: none; color: #FFF; background-color: #3ED085; border-radius: 40px; font-size: 14px;">{{$t('offer-card.text-timer')}}</b-badge>

                                        <div class="d-flex special-countdown ml-1" style="align-items: flex-start; z-index: 2;">  
                                            <div class="d-flex counter-offer">
                                                <div>{{ countdown.timeObj.h }}</div>
                                                    <span class="text-muted">:</span>
                                                <div>{{ countdown.timeObj.m }}</div>
                                                    <span class="text-muted">:</span>
                                                <div>{{ countdown.timeObj.s }}</div>
                                            </div>
                                            <div class="text-muted" style="font-size: 12px; font-weight: 400; margin-left: 6px; line-height: 1.2; z-index: 2;">
                                                {{$t('offer-card.offer')}}<br>{{$t('offer-card.valid')}}
                                            </div>
                                        </div>
                                    </div>

                                </b-card-title>

                            </template>
                        </countdown>

                        <b-card-title style="font-size: 20px; color: #FFF; line-height: 28px;">
                            {{$t('catalog.start-earning-right')}}
                        </b-card-title>
                    </div>
                    <div style="width: 43%">
                        <img alt="" style="width: 100%;" src="~@/assets/images/banner-v2.png">
                    </div>
                </div>

            </b-card-body>
        </showAt>

        <showAt breakpoint="small">
             <b-card-body>
            
                <div class="d-flex" style="height: 100%; flex-direction: column;">
                    <div class="d-flex" style="flex-direction: column; justify-content: space-evenly; padding: 16px 24px 0px 24px; width: 100%;">
                        <countdown :end-time="new Date(offers.until * 1000).getTime()" ref="countdown">
                            
                            <template
                                v-slot:process="countdown">
                                <b-card-title class="d-flex" style="align-items: flex-start; justify-content: space-between;">

                                    <div class="d-flex" style="flex-direction: column;">  
                                        <b-badge variant="success" style="padding: 8px 16px; text-transform: none; color: #FFF; background-color: #3ED085; border-radius: 40px; font-size: 14px;">{{$t('offer-card.text-timer')}}</b-badge>

                                        <div class="d-flex special-countdown" style="align-items: flex-start;">  
                                            <div class="d-flex counter-offer">
                                                <div>{{ countdown.timeObj.h }}</div>
                                                    <span class="text-muted">:</span>
                                                <div>{{ countdown.timeObj.m }}</div>
                                                    <span class="text-muted">:</span>
                                                <div>{{ countdown.timeObj.s }}</div>
                                            </div>
                                            <div class="text-muted" style="font-size: 12px; font-weight: 400; margin-left: 6px; line-height: 1.2; z-index: 2;">
                                                {{$t('offer-card.offer')}}<br>{{$t('offer-card.valid')}}
                                            </div>
                                        </div>
                                    </div>

                                </b-card-title>

                            </template>
                        </countdown>

                        <b-card-title style="font-size: 20px; color: #FFF; line-height: 28px;">
                            {{$t('catalog.start-earning-right')}}
                        </b-card-title>
                    </div>
                    <div style="padding: 0px 50px">
                        <img alt="" style="width: 100%" src="~@/assets/images/banner-v2.png">
                    </div>
                </div>

            </b-card-body>
         </showAt>

    </b-card>
</template>

<script>

    import offers from "@/modules/contents/offers"

    export default {

        data() {
            return {
                offers,
            }
        },
        props: {
        },
        methods: {
        },
        components: {

        },
        computed: {
        },
        watch: {
        },
        mounted() {
        }

    }

</script>

<style lang="scss" scoped>
    
    .card-special-v2 {
        
        .counter-offer {
            align-items: center;
        }
        
        .counter-offer div {
            margin: 0 2px;
            color:#FFF !important;
            background-color: rgba(66, 111, 246, 1) !important;
            width: 27px; 
            height: 27px;
            text-align: center;
            line-height: 27px;
            border-radius: 4px;
            font-size: 16px;
            font-weight: 500;
           
        }
    
        .counter-danger div {
            background-color: #878787 !important;
        }

    }

    .things > .content {
        float: left;
        width: 50%;
        /* height: 500px; */
        -webkit-box-sizing: border-box; 
        -moz-box-sizing: border-box;    
        box-sizing: border-box;
        position: relative;
    }

    /* Arrow */

    .arrow {
    position: relative;
    margin: 0 auto;
    width: 100px;
    }

    .arrow .curve {
    border: 2px solid #9CA2B1;
    border-color: transparent transparent transparent #9CA2B1;
    height: 75px;
    width: 100px;
    border-radius: 230px 0 0 150px;
    }

    .arrow .point {
    position: absolute;
    left: 10px;
    top: 66px;
    }

    .arrow .point:before, .arrow .point:after {
    /* border: 1px solid #9CA2B1 ; */
    background: #9CA2B1;
    width: 2px;
    height: 10px;
    content: "";
    position: absolute;
    }

    .arrow .point:before {
    top: -6px;
    left: -7px;
    transform:rotate(-74deg);
    -webkit-transform:rotate(-74deg);
    -moz-transform:rotate(-74deg);
    -ms-transform: rotate(-74deg);
    }

    .arrow .point:after {
    top: -9px;
    left: -1px;
    transform:rotate(12deg);
    -webkit-transform: rotate(12deg);
    -moz-transform:rotate(12deg);
    -ms-transform: rotate(12deg);
    }

    .disabled {
        opacity: 0.4
    }

    .card-special-v2 .card-body {
        padding: 0px !important;
    }

</style>