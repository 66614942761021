<template>

    <b-card v-if="offers.items.length > 0" bg-variant="transparent" no-body>
        <b-card-body style="padding: 0; position: relative;">
            <offer-card/>              
        </b-card-body>
        
    </b-card>
    
</template>

<style>
  
</style>

<script>
    
    import offers from "@/modules/contents/offers"
    import OfferCard from "@/components/SpecialOfferBannerV2"
    
    export default {

        data() {
            return {
                offers,
            }
        },
        methods: {

        },
        components: {
            OfferCard
        },
        computed: {
          
        },
        watch: {

        },
        mounted() {
           
        }

    }

</script>