<template>

    <b-card v-if="offers.items.length > 0" bg-variant="transparent" no-body>
        
        <b-card-header v-if="showTimer">
            <b-card-title class="d-flex flex-column">
                
                <div style="margin-right: 8px; margin-bottom: 8px;">{{$t('offer-card.text-timer')}}</div>
                
                <div class="d-flex">
                    <countdown :end-time="new Date().getTime() + (60000 * 321)">

                        <template
                          v-slot:process="countdown">
                            <div class="d-flex counter">
                                <div>{{ countdown.timeObj.h }}</div>
                                    <span class="text-muted">:</span>
                                <div>{{ countdown.timeObj.m }}</div>
                                    <span class="text-muted">:</span>
                                <div>{{ countdown.timeObj.s }}</div>
                            </div>
                        </template>

                    </countdown>

                    <div class="text-muted" style="font-size: 12px; font-weight: 400; margin-left: 6px;" :v-html="$t('offer-card.offer-valid')">
                        {{$t('offer-card.offer')}}
                        <br>
                        {{$t('offer-card.valid')}}
                    </div>
                </div>
                
            </b-card-title>
        </b-card-header>
        
        
        <b-card-body style="padding: 0">
            <swiper :options="swiperOptions" >
                <swiper-slide v-for="(item, index) in offers.items" :key="index">
                    <offer-card :item="item" />
                </swiper-slide>
            </swiper>
        </b-card-body>
        
    </b-card>
    
</template>

<style>
    
    .counter {
        align-items: center;
    }
    
    .counter div {
        margin: 0 2px;
        color: #FFF;
        background-color: var(--theme-color);
        width: 27px; 
        height: 27px;
        text-align: center;
        line-height: 27px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
       
    }
</style>

<script>
    
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'
    import OfferCard from "@/components/OfferCard"
    import offers from "@/modules/contents/offers"
    
    export default {
        props: {
            showTimer: {type: Boolean, default: true}
        },
        data() {
            return {
                offers,
                swiperOptions: {
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    spaceBetween: 0,
                    slidesOffsetBefore: 0
                },
            }
        },
        methods: {

        },
        components: {
            Swiper,
            SwiperSlide,
            OfferCard
        },
        watch: {

        },
        mounted() {

        }

    }

</script>