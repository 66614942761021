

    import $request from "@/modules/request/"

    export default {
        
        cart: {},
        
        items: [],
        last_page: 0,
        total_count: 0,
        current_page: 0,
        has_more_pages: false,

        manufacturers: [],
        coins: [],
        
        ifIsInCart( miner ) {
            return this.cart.hasOwnProperty( miner.id );
        },
        
        get( forceUpdate = false, filter = null, cb = null ) {
            if( this.items.length > 0 && forceUpdate === false) return;
            $request.get("miners.list", {filter}).then( rsp => {
                this.items = rsp.items;
                this.last_page = rsp.last_page;
                this.total_count = rsp.total_count;
                this.current_page = rsp.current_page;
                this.has_more_pages = rsp.has_more_pages;

                this.manufacturers = rsp.manufacturers;
                this.coins = rsp.coins;

                if (cb) cb();
            });
        }
        
        
    }
